import { default as React, useMemo } from "react"
import algoliasearch from "algoliasearch/lite"
import {
  Configure,
  Hits,
  InstantSearch,
  PoweredBy,
  RefinementList,
} from "react-instantsearch-hooks-web"
import {
  Box,
  Button,
  Drawer,
  Grid,
  Hidden,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import Seo from "../components/seo"
import Stats from "../components/stats"
import Hit from "../components/hit"
import CustomSearchBox from "../components/custom-search-box"
import Pagination from "../components/pagination"

import {
  FilterAlt,
  Tune,
  Close,
  ExpandMore,
} from "@mui/icons-material"


const pageDescription = category => {
  const categoryToDescription = {
    "Plated Desserts":
      "Welcome to our plated desserts page! Our search results feature a variety of elegant and professional plated dessert recipes from top pastry chefs around the world. From classic flavor combinations to unique and creative twists, you'll find the most inspiring and mouth-watering plated dessert recipes to impress your guests or customers. Browse our results and elevate your dessert game with these professional-quality recipes.",
    "Moulded Bonbons":
      "Welcome to our moulded bonbons page! Our search results feature a range of exquisite and professional moulded bonbon recipes from expert pastry chefs. From classic chocolate and caramel flavors to more experimental and creative combinations, you'll find the most inspiring and impressive moulded bonbon recipes to add to your dessert repertoire. Browse our results and learn how to make these artisanal sweets with the guidance of top pastry chefs.",
    "Gelato & Ice Cream":
      "Welcome to our gelato and ice cream page! Our search results feature only the best and most delicious gelato and ice cream recipes from professional pastry chefs around the world. Whether you prefer classic flavors like vanilla and chocolate or more exotic and inventive combinations, our curated results have got you covered. Browse our recipes and learn how to make creamy and flavorful gelato and ice cream like a pro.",
    Tarts:
      "Welcome to our tarts page! Our search results feature a variety of delectable and professional tart recipes from expert pastry chefs. From classic fruit tarts to more unique and creative flavor combinations, you'll find the most inspiring and mouth-watering tart recipes to impress your guests or customers. Browse our results and learn how to make these beautiful and delicious pastries with the guidance of top pastry chefs.",
    Cakes:
      "Welcome to our cakes page! Our search results feature a range of decadent and professional cake recipes from expert pastry chefs. From classic flavors like vanilla and chocolate to more elaborate and creative designs, you'll find the most inspiring and impressive cake recipes to celebrate any occasion. Browse our results and learn how to make these stunning and delicious cakes with the guidance of top pastry chefs.",
    "Chocolate Bars":
      "Welcome to our chocolate bars page! Our search results feature a range of indulgent and professional chocolate bar recipes from expert pastry chefs. From classic milk and dark chocolate to more unique and exotic flavor combinations, you'll find the most inspiring and mouth-watering chocolate bar recipes to satisfy your sweet tooth. Browse our results and learn how to make these artisanal chocolate bars with the guidance of top pastry chefs.",
    Truffles:
      "Welcome to our truffles page! Our search results feature a variety of rich and professional truffle recipes from top pastry chefs around the world. From classic chocolate truffles to more experimental and creative flavor combinations, you'll find the most inspiring and impressive truffle recipes to elevate your dessert game. Browse our results and learn how to make these luxurious and delicious confections like a pro.",
    Entremets:
      "60+ High quality professional entremet recipes! We've curated a range of elegant and professional entremet recipes from expert pastry chefs.<br><br>From classic flavor combinations to more unique and creative twists, you'll find the most inspiring and mouth-watering entremet recipes to impress your guests or customers. <br><br>Browse our results and learn how to make these multi-layered and sophisticated desserts with the guidance of top pastry chefs.",
    Macarons:
      "Welcome to our macarons page! Our search results feature a variety of delicate and professional macaron recipes from top pastry chefs around the world. From classic flavors like raspberry and pistachio to more exotic and inventive combinations, you'll find the most inspiring and impressive macaron recipes to add to your dessert repertoire. Browse our results and learn how to make these iconic and delicious French sweets like a pro.",
    "Chocolate Tablets":
      "Indulge in the rich and decadent world of chocolate tablets with our handpicked selection of recipes from top pastry chefs. Our search engine results feature only the best and most luxurious chocolate tablets, crafted with the finest ingredients and techniques. From milk to dark chocolate and everything in between, our results will satisfy your chocolate cravings like no other.",
    Eclairs:
      "Discover the perfect balance of crispy choux pastry and rich, creamy filling with our selection of eclair recipes from professional pastry chefs. Our search results feature a range of classic and modern eclair flavors, from classic vanilla and chocolate to unique variations like matcha and lavender. With our carefully curated collection of recipes, you can create perfect eclairs every time.",
    "Yule Logs":
      "Bring a touch of festive magic to your dessert table with our handpicked selection of yule log recipes from top pastry chefs. Our search engine results feature a range of unique and delicious yule log flavors, from classic chocolate to fruity variations like cranberry and orange. Whether you're hosting a holiday gathering or simply want to indulge in a decadent treat, our results will help you create the perfect yule log.",
    Mousses:
      "Indulge in the rich and velvety texture of mousses with our handpicked selection of recipes from top pastry chefs. From classic chocolate to fruity variations, our results feature only the best and most decadent mousses.",
    Cookies:
      "From crunchy on the outside to soft on the inside, cookies are a timeless dessert that can be enjoyed any time of day. Our search results feature a carefully curated selection of cookie recipes from renowned pastry chefs, ensuring that you can create the perfect treat every time.",
    Cheesecakes:
      "Creamy, tangy, and sweet, cheesecakes are a crowd-pleasing dessert that can be served at any occasion. Our search engine results only feature recipes from top pastry chefs, so you can rest assured that you're getting the best of the best.",
    Drinks:
      "Take your dessert game to the next level with our selection of drink recipes from professional pastry chefs. From rich and indulgent hot chocolate to refreshing and fruity cocktails, our search results are sure to satisfy your sweet tooth.",
    Spreads:
      "Looking to add a touch of sweetness to your morning toast or afternoon snack? Our search results feature a range of delicious spread recipes from the best pastry chefs around. Whether you prefer chocolate, fruit, or nut-based spreads, we've got you covered.",
  }

  return categoryToDescription[category]
}

export default function SearchDesserts({ pageContext }) {
  const category = pageContext.category
  const title = pageContext.title ? pageContext.title : "Dessert Recipes"
  const description = pageDescription(category)
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  const [filterDrawerOpen, setFilterDrawerOpen] = React.useState(false)

  const handleFilterClick = () => {
    setFilterDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setFilterDrawerOpen(false)
  }

  return (
    <>
      <Seo title={title} description={description} />
      <InstantSearch
        searchClient={searchClient}
        indexName="dev_FIND_COUVERTURE"
        routing={true}
      >
        {category && <Configure filters={`categories: '${category}'`} />}
        <Grid container spacing={2}>
          <Hidden smDown>
            <Grid item sm={3}>
              {/* <Typography component="h2" variant="h5">
                {title}
              </Typography> */}
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={9} lg={6}>
            <Typography component="h2" variant="h3" mb={4}>
              {title}
            </Typography>
            <Typography component="p" variant="p" mb={4}>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </Typography>

            {category === "Entremets" && (
              <Box mb={4}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography component="h3" variant="h6">
                      What is an entremet?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" mb={2}>
                      An entremet is a type of multi-layered dessert that
                      originated in French cuisine. It is typically composed of
                      various elements such as sponge cake, mousse, ganache, and
                      other complementary components.
                    </Typography>
                    <Typography variant="body1" mt={2}>
                      Entremets are known for their elegant and elaborate
                      presentations. They often feature contrasting textures,
                      flavors, and colors to create a harmonious combination.
                      The dessert is typically assembled in a mold or ring,
                      allowing for distinct layers to be stacked and set.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography component="h3" variant="h6">
                      What makes a good entremet?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1">
                      <ul>
                        <li>
                          Flavors: A good entremet achieves a harmonious balance
                          of flavors, with each element complementing the
                          others.
                        </li>
                        <li>
                          Textures: The dessert incorporates a variety of
                          textures, creating a pleasing mouthfeel through the
                          combination of light, creamy, crunchy, and fresh
                          elements.
                        </li>
                        <li>
                          Visual Appeal: Presentation is crucial, with an
                          aesthetically pleasing design, well-defined layers,
                          and attractive decorations.
                        </li>
                        <li>
                          Temperature and Texture Stability: The entremet
                          maintains its structure and texture throughout
                          serving, ensuring it holds its shape and is easy to
                          slice.
                        </li>
                        <li>
                          Balanced Sweetness: The sweetness level is carefully
                          balanced to enhance the overall experience without
                          being overly sugary.
                        </li>
                        <li>
                          Innovation and Creativity: A good entremet showcases
                          creativity and innovation, whether through unique
                          flavor combinations, unexpected textures, or inventive
                          techniques.
                        </li>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            <CustomSearchBox />
          </Grid>

          <Grid
            item
            xs={12}
            sm={0}
            justifyContent="center"
            sx={{ display: { xs: "flex", sm: "none", lg: "none" } }}
          >
            <Box
              sx={{
                display: {
                  xs: "block",
                  sm: "none",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <Button
                variant="outlined"
                size="small"
                startIcon={<Tune />}
                onClick={handleFilterClick}
              >
                Filters
              </Button>
              <Drawer
                anchor="top"
                open={filterDrawerOpen}
                onClose={handleDrawerClose}
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{ display: { sm: "none" } }}
              >
                <Stack spacing={4} p={4}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      variant="h6"
                      sx={{
                        display: "inline-flex",
                        verticalAlign: "middle",
                        alignItems: "center",
                      }}
                    >
                      <Tune />
                      Filters
                    </Typography>
                    <Typography
                      onClick={handleDrawerClose}
                      variant="subtitle1"
                      sx={{
                        display: "inline-flex",
                        verticalAlign: "middle",
                        alignItems: "center",
                      }}
                    >
                      Close
                      <Close />
                    </Typography>
                  </Stack>

                  {!category && (
                    <Stack spacing={2} mb={2}>
                      <Typography variant="subtitle2">Categories</Typography>
                      <RefinementList
                        attribute="categories"
                        showMore={true}
                        classNames={{
                          list: "refinement-list",
                          item: "refinement-list-item",
                          count: "refinement-list-count",
                          labelText: "refinement-list-label-text",
                          showMore: "refinement-list-show-more",
                          disabledShowMore:
                            "refinement-list-disabled-show-more",
                        }}
                      />
                    </Stack>
                  )}

                  <Stack spacing={2} mb={2}>
                    <Typography variant="subtitle2">Ingredients</Typography>
                    <RefinementList
                      attribute="ingredients"
                      searchable={true}
                      operator="and"
                      classNames={{
                        list: "refinement-list",
                        item: "refinement-list-item",
                        count: "refinement-list-count",
                        labelText: "refinement-list-label-text",
                        searchBox: "refinement-list-search-box",
                      }}
                    />
                  </Stack>

                  <Stack spacing={2}>
                    <Typography variant="subtitle2">Site</Typography>
                    <RefinementList
                      attribute="site_name"
                      showMore={true}
                      classNames={{
                        list: "refinement-list",
                        item: "refinement-list-item",
                        count: "refinement-list-count",
                        labelText: "refinement-list-label-text",
                        showMore: "refinement-list-show-more",
                        disabledShowMore: "refinement-list-disabled-show-more",
                      }}
                    />
                  </Stack>
                </Stack>
              </Drawer>
            </Box>
          </Grid>

          <Hidden lgDown>
            <Grid item sm={3}></Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item sm={3}>
              <Stack spacing={3}>
                <Typography
                  variant="h6"
                  sx={{
                    display: "inline-flex",
                    verticalAlign: "middle",
                    alignItems: "center",
                  }}
                >
                  <FilterAlt sx={{ marginRight: "8px" }} />
                  Filters
                </Typography>

                {!category && (
                  <Stack spacing={2} mb={2}>
                    <Typography variant="subtitle2">Categories</Typography>
                    <RefinementList
                      attribute="categories"
                      showMore={true}
                      classNames={{
                        list: "refinement-list",
                        item: "refinement-list-item",
                        count: "refinement-list-count",
                        labelText: "refinement-list-label-text",
                        showMore: "refinement-list-show-more",
                        disabledShowMore: "refinement-list-disabled-show-more",
                      }}
                    />
                  </Stack>
                )}

                <Stack spacing={2} mb={2}>
                  <Typography variant="subtitle2">Ingredients</Typography>
                  <RefinementList
                    attribute="ingredients"
                    searchable={true}
                    operator="and"
                    classNames={{
                      list: "refinement-list",
                      item: "refinement-list-item",
                      count: "refinement-list-count",
                      labelText: "refinement-list-label-text",
                      searchBox: "refinement-list-search-box",
                    }}
                  />
                </Stack>

                <Stack spacing={2}>
                  <Typography variant="subtitle2">Site</Typography>
                  <RefinementList
                    attribute="site_name"
                    showMore={true}
                    classNames={{
                      list: "refinement-list",
                      item: "refinement-list-item",
                      count: "refinement-list-count",
                      labelText: "refinement-list-label-text",
                      showMore: "refinement-list-show-more",
                      disabledShowMore: "refinement-list-disabled-show-more",
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={9} lg={6}>
            <Stats title={title} />
            <Hits
              hitComponent={Hit}
              classNames={{
                list: "search-result-ol",
                item: "search-result-li",
              }}
            />
            <Pagination />
            <Grid item xs={3}>
              <PoweredBy />
            </Grid>
          </Grid>
        </Grid>
      </InstantSearch>
    </>
  )
}
