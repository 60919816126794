import {useConnector, useInstantSearch} from "react-instantsearch-hooks-web";
import {Typography} from "@mui/material";
import {default as React} from "react";
import connectStats from "instantsearch.js/es/connectors/stats/connectStats"

function useStats(props) {
  return useConnector(connectStats, props)
}

function Stats(props) {
  const { nbHits, query, nbPages, page } = useStats(props)
  const { status } = useInstantSearch()

  const title = props.title.toLowerCase()
  const naturalPageNumber = page + 1 // page is zero indexed

  if (status === "stalled") {
    return null
  }

  return (
    <Typography gutterBottom variant="h6" component="div">
      {nbHits} {title}
      {query ? ` for "${query}"` : ""}
      {nbPages > 1 ? ` - Page ${naturalPageNumber} of ${nbPages}` : ""}
    </Typography>
  )
}

export default Stats
